import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="blue">Sergey Moraru </span>
            from <span className="blue"> Charlotte, NC.</span>
            <br />
            <br /> I came to the US from Ukraine as a child.
            <br /> Most of my life I have worked in the millwork industry.
            <br />
            <br />
            I'm chasing my lifelong dream of becoming a software engineer by
            enrolling in a program. It's not just changing careers; it's
            following my heart with my family's support. I'm diving into coding
            and innovation, not just for work, but because it's my passion.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Fishing and Outdoor Activities
            </li>
            <li className="about-activity">
              <ImPointRight /> Personal Coding Projects
            </li>
            <li className="about-activity">
              <ImPointRight /> Traveling
            </li>
          </ul>

          <p className="blue">
            "Success is not final, failure is not fatal: It is the courage to
            continue that counts."{" "}
          </p>
          <footer className="blockquote-footer">Winston Churchill</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
