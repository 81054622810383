import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import MintedCrownScreenshot from "../../Assets/Screenshots/MintedCrownScreenshot.png";
import GoldenDragon from "../../Assets/Screenshots/GoldenDragon.png";
import BestAuto from "../../Assets/Screenshots/BestAuto.png";
import sandwichShop from "../../Assets/Screenshots/sandwichShop.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="blue">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sandwichShop}
              isBlog={false}
              title="Sandwich Shop"
              description="My first astro project. The application is build using Astro and React."
              ghLink="https://github.com/Sergemoraru/sandwichshop"
              demoLink="https://sndwichshop.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={BestAuto}
              isBlog={false}
              title="Best Auto Deals"
              description="A web application for buying and selling used cars. The application is build using Next.js, Node.js, and Tailwind CSS. "
              ghLink="https://github.com/Sergemoraru/best-auto-main"
              demoLink="https://best-auto-main.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={GoldenDragon}
              isBlog={false}
              title="Golden Dragon"
              description="A web application for a restaurant. The application is build using Next.js, Typescript, Tailwind CSS, and is deployed with Vercel. "
              ghLink="https://github.com/Sergemoraru/chinese-restaurant-website"
              demoLink="https://chinese-restaurant-website.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MintedCrownScreenshot}
              isBlog={false}
              title="Minted Crown Marketing"
              description="A web application for a marketing agency. The application is build using Next.js, Node.js, and Tailwind CSS. "
              ghLink="https://github.com/Sergemoraru/MintedCrown"
              demoLink="https://minted-crown.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
